import React from "react";
import { Helmet } from "react-helmet-async";
import Social from "../components/molecules/social-icons";
import MainNav from "../components/MainNav/mainnav";
import styled from "styled-components";

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Hero = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;

    h1 {
      font-size: 60px;
    }

    p {
      font-size: 24px;
      line-height: 40px;
    }

    h1,
    p {
      background: linear-gradient(160deg, navy, indigo, purple, blueviolet);
      -webkit-background-clip: text;
      -moz-background-clip:text;
      -webkit-text-fill-color: transparent;
    }


    @media screen and (max-width: 600px) {
      h1 {
        font-size: 42px;
        line-height: normal;
      }
  
      p {
        font-size: 20px;
        line-height: 33px;
      }
    }

    @media screen and (max-width: 400px) {
      align-items: start;

      h1 {
        font-size: 26px;
        line-height: normal;
      }
  
      p {
        font-size: 16px;
        line-height: 27px;
      }
    }
`;

const Main = styled.main`
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;

  @media screen and (max-width: 600px) {
    align-items: start;
  }
`;

const Index = () => (
  <Main>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Erin Olmon &middot; User Experience Research &amp; Design</title>
      <link rel="canonical" href="https://erinolmon.net" />
    </Helmet>
    <Hero>
      <h1>Hi. I'm Erin. I specialize in Experience Research &amp; Design.</h1>
      <p>
        I make technology easier to use. Better to use. More fun to use. More
        interesting to use. I believe not only should technology work, but it
        should work well, seamlessly, and be delightful. It should resonate with
        people and tug at their emotions.
      </p>
      <BottomRow>
        <Social />
        <MainNav />
      </BottomRow>
    </Hero>
  </Main>
);

export default Index;