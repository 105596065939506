import React from "react";
import styled from "styled-components";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Social = styled.div`
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  margin-right: 12px;
  cursor: pointer;
  display: inline-block;

  

  :focus,
  :hover {
    svg {
      stroke: purple;
      fill: purple;
    }
  }

  > * {
    color: white;
  }
  svg {
    stroke: indigo;
    fill: indigo;
  }

  a{
    border-bottom: none;
  }
`;

const SocialList = () => (
  <div>
    <Social>
      <a
        href="https://github.com/erinoerin"
        aria-label="Visit Erin's Github Profile"
        title="Visit Erin's Github Profile"
      >
        <FaGithub />
      </a>
    </Social>

    <Social>
      <a
        href="https://www.linkedin.com/in/erinolmon/"
        aria-label="Visit Erin's LinkedIn Profile"
        title="Visit Erin's LinkedIn Profile"
      >
        <FaLinkedin />
      </a>
    </Social>
  </div>
);

export default SocialList;